.match-variant-list {
  table {
    thead tr {
      th:first-child,
      th:nth-child(4) {
        border-right: 1px solid #0000001a;
      }
    }
    tbody {
      th,
      td:nth-child(4) {
        border-right: 1px solid #0000001a;
      }
    }
  }
}

.obsolete-row {
  background-color: #f4cccc;
  &:hover {
    th,
    td {
      background: #f9fafb1c !important;
    }
  }
}

.new-row {
  background-color: #d9ead3;
  &:hover {
    th,
    td {
      background: #f9fafb1c !important;
    }
  }
}

.price-field-wrapper {
  max-width: 110px;
}
