.orders-wrapper-loader {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-wrapper {
  .Polaris-Page {
    max-width: 80.25rem;

    .Polaris-Banner {
      margin-bottom: 1rem;
    }
  }
}

.refund-input {
  width: 105px;
}
