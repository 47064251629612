.Polaris-Page {
  max-width: 80.25rem;
  width: 100%;
}

.Polaris-Tooltip-TooltipOverlay {
  pointer-events: all;
}

.Polaris-Sheet__Container {
  z-index: 520;
}

.Polaris-Frame__Content {
  display: flex;
  flex-direction: column;

  .Polaris-SkeletonPage__Page {
    width: 100%;
  }

  .Polaris-FooterHelp {
    margin-top: auto;
  }
}
