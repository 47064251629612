#approval-button {
  border-radius: 8px;
  max-width: 100px;

  &:disabled {
    .Polaris-Button__Icon {
      margin: auto;
    }

    & svg {
      stroke: gray;
    }
  }

  .Polaris-Button__Content {
    line-height: 11px;

    .Polaris-Button__Icon {
      margin: auto;
    }
  }

  & svg {
    fill: none;
    width: 18px;
    height: 18px;
    stroke: #6160af;
  }
}
