@import '../banner.scss';

.steps {
  .step {
    display: inline-grid;
    grid-template-columns: 5em auto;
    width: 100%;
    padding: 0.5em;
    .annotation {
      font-size: 1.2em;
    }
  }
  padding-bottom: 0.5em;
}
