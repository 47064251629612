.seller-condition-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.description {
  flex: 1;
}

.number {
  font-weight: 600;
}

.read-terms-and-conditions {
  display: flex;
  align-items: center;
}

.approved-wrapper {
  display: flex;
}

.approved-text {
  flex: 1;
}
