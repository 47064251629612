.loading-layout {
  position: absolute;
  top: 50%;
  margin: -2.2rem 0 0 -2.2rem;
  left: 50%;
  .Polaris-TextStyle--variationCode {
    transform: translateX(-40%);
    margin: 10px 0 0;
    display: flex;

    .app-button {
      margin-left: 0.5rem;
    }
  }
}
