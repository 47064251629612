@import '../../styles/index-table-link-to-platform.scss';

.product-list {
  padding: 0.7em 0;

  .Polaris-Card__Header {
    padding-left: 1rem;
  }

  .filter-container {
    padding: 16px;
    display: flex;
    flex-direction: column;

    &__sku-limit {
      margin: 1rem 1rem 0;
    }

    .filters {
      max-width: 100%;
      flex: 1;
    }
  }

  .processing-tag {
    display: flex;

    .description {
      padding-left: 0.5em;
    }
  }

  .Polaris-IndexTable__TableCell {
    position: unset !important;
  }

  .seller-wrapper {
    display: flex;
  }

  .row-links {
    display: flex;
    flex-direction: row;
  }
}

.radio-popover-wrapper {
  .Polaris-Choice {
    align-items: center;
    .form_input_error_wrapper .form_error_wrapper {
      width: 200px;
      margin-top: -10px;
    }
  }
}

.no-products {
  padding: 1em;
}

.marketplace-product-list {
  .seller-wrapper {
    display: flex;
  }
}
