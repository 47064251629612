.payment-confirmation-modal {
  .payout-summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .confirmation-form {
    display: flex;
    padding: 1rem 0;
    .date-picker-wrapper {
      max-width: 50%;
      margin-right: 2rem;
      .label {
        padding-bottom: 0.5rem;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
  }
}
