.reason {
  display: flex;

  .reason-select {
    margin-right: 1em;
  }

  .comment-input {
    width: 100%;
  }
}

.restock-flag {
  display: flex;
  flex-direction: column;
  margin-top: 1em;

  .warning-message {
    display: flex;
    align-items: center;
    margin-left: 24px;

    svg {
      margin-right: 5px;
    }
  }
}

.input.quantity {
  min-width: 6rem;
}
