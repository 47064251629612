.settings-layout {
  .Polaris-Page {
    max-width: 80.25rem;
  }
}

.narrow-text-field {
  .Polaris-TextField {
    max-width: 30%;
  }
}

.email-select {
  min-width: 120px;
}

.visibility-mode-select {
  & > div {
    display: inline-block;
  }

  & > span {
    margin-right: 10px;
  }

  & + .Polaris-Stack {
    margin-top: 0;
  }
}

.payout-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  .Polaris-TextField,
  .Polaris-Select {
    margin-right: 1em;
    margin-bottom: 0.5em;
  }
  .files_wrapper {
    grid-column: 1/-1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    .file_wrapper {
      position: relative;
      .caption {
        position: absolute;
        bottom: -23px;
      }
    }
    .w9_from {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    .drop-zone__wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .error {
        color: rgba(216, 44, 13, 1);
      }
      .drop-zone_with-link {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .delete_file {
        cursor: pointer;
      }
      .disabled_link {
        pointer-events: none;
      }
      .uploaded_file-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.setup-tab-content {
  padding-top: 1em;
  width: 100%;

  .retailers-tab {
    .Polaris-DataTable__Cell--firstColumn {
      width: 30%;
    }

    .customer-id,
    .handling-fee,
    .order-prefix {
      padding-top: 8px;
    }
  }
}

.adjustment {
  .Polaris-Connected {
    max-width: 50%;
  }

  .use-price,
  .use-price-adjustment {
    padding-top: 1.5rem;
  }

  .use-price-adjustment {
    padding-left: 3rem;
  }
}

.marketplace-visibility-description,
.marketplace-section .description {
  p {
    padding: 0.5em 0;
  }
}

.profile-section {
  &__image-block {
    .Polaris-Layout__AnnotatedSection {
      padding-bottom: var(--p-space-4);
      border-bottom: var(--p-border-divider);

      .Polaris-Layout__AnnotationContent {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .Polaris-Card {
          height: fit-content;

          .Polaris-Card__Section {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      .Polaris-Spinner {
        margin: auto 0 auto 1.5rem;
      }
    }

    &__description {
      margin-right: 1rem;

      p {
        margin-top: 0.5rem;
      }
    }

    &__logo {
      display: flex;
      flex-direction: column;
      width: fit-content;
      align-items: center;
      margin-left: 1.5rem;

      .Polaris-Thumbnail {
        width: 5.6rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  &__bio-desription {
    margin-bottom: 0.5rem;
  }

  &__license-block {
    .Polaris-Layout__AnnotatedSection {
      padding-top: var(--p-space-4);
      border-top: var(--p-border-divider);

      .Polaris-Choice {
        display: flex;
        justify-content: flex-end;
        margin: 0.5rem 0;
      }
    }

    .Polaris-Card {
      height: fit-content;

      .Polaris-Card__Section {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .Polaris-Spinner {
      margin: auto 0 auto 1.5rem;
    }

    &__form {
      width: 100%;

      .Polaris-Labelled__Error {
        margin-bottom: 0.5rem;
      }

      .Polaris-TextField,
      .Polaris-Select {
        margin-right: 1em;
      }

      .Polaris-Labelled__LabelWrapper {
        margin-top: 1em;
      }

      &__fields {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .Polaris-Labelled__LabelWrapper {
          margin-top: 0;
        }
      }
    }

    &__file {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr;

      &__description {
        margin-right: 1rem;

        p {
          margin-top: 0.5rem;
        }
      }

      &__view {
        margin-left: 1.5rem;

        .Polaris-Thumbnail {
          width: 5.6rem;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
