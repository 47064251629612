.purchase-order-details-layout {
  .Polaris-Page {
    max-width: none;
    padding-bottom: 0;
  }

  .Polaris-Page-Header {
    padding-bottom: 0;
  }

  .order-date {
    width: fit-content;
    padding: 5px 0 10px;
  }
}
