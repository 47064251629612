.supplier {
  display: flex;

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;

    .Polaris-Thumbnail {
      border: none;
      width: 8rem;
    }
  }

  &__info {
    margin: 0 3rem;

    .Polaris-DisplayText {
      .Polaris-TextStyle--variationStrong {
        text-decoration: underline;
      }
    }

    &__option {
      display: flex;
      margin-top: 0.5rem;

      .Polaris-Icon,
      img {
        margin: 0 1rem 0 0;
      }
    }
  }

  &__bio {
    width: 50%;
    margin-left: auto;

    .Polaris-DisplayText {
      margin-bottom: 0.5rem;
    }

    &.not_hidden {
      .Polaris-TextStyle--variationSubdued {
        -webkit-box-orient: unset;
      }
    }

    .Polaris-TextStyle--variationSubdued {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    .app-button {
      width: fit-content;
      margin-left: auto;
    }
  }
}
