.marketplace-wrapper {
  .Polaris-Page {
    max-width: 80.25rem;

    .Polaris-Page-Header {
      padding-bottom: 0.5rem;
    }
  }

  &__product_view {
    display: flex;
    justify-content: flex-end;

    margin-top: 0.7rem;
  }
}
