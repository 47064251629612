.product-level-section {
  display: flex;

  .product-image {
    padding-right: 4em;
  }
}

.variant-level-section {
  .text-field {
    width: 90px;

    .basic_formik_input {
      width: 90px;
    }

    .Polaris-Connected {
      width: 90px;
    }
  }

  .price-sync {
    cursor: pointer;

    .price-value {
      text-align: right;
    }

    > span {
      display: flex;

      .Polaris-Icon {
        margin: 0 0.3rem 0 0;
      }
    }
  }

  .cursor-default {
    .price-value {
      text-align: right;
    }

    > span {
      display: flex;

      .Polaris-Icon {
        margin: 0 0.3rem 0 0;
      }
    }
  }
}

.radio-popover-wrapper {
  .disable-sync-wrapper,
  .sync-cost-wrapper {
    display: flex;
    align-items: center;

    form {
      width: 70px;

      .basic_formik_input {
        padding: 0;
        text-align: center;
      }
    }
  }

  .price-label {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .app-button {
    margin-left: auto;
    width: fit-content;
  }
}
