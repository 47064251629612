.import-list-skeleton {
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;

  .Polaris-SkeletonThumbnail {
    width: 14rem;
    height: 14rem;
  }

  &__text {
    margin-left: 1rem;
    width: 100%;
  }
}
