@import '../../../../../../core/styles/index-table-link-to-platform.scss';

.sync-matches {
  .new-count {
    color: green;
  }

  .obsolete-count {
    color: red;
  }

  .product-title {
    padding-left: 15px;
    display: flex;
    flex-direction: column;

    .crowdship-logo {
      padding-right: 0.3em;
    }
  }

  .thumbnail-wrapper {
    &.crowdship {
      .Polaris-Thumbnail {
        border-color: #070739;
      }

      .crowdship-logo {
        position: absolute;
        top: -57px;
        left: 44px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
