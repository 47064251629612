.no-matches {
  padding: 1em;
}
.sync-matches {
  padding-top: 1em;
}
.filter-container {
  padding: 16px;
  display: flex;
  .filters {
    flex: 1;
  }
}
