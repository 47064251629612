.invoice-details-layout {
  .Polaris-Page {
    max-width: 80.25rem;
  }

  &__csv-button {
    width: fit-content;
    padding-left: 16px;
  }

  &__addition-info {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;

    .Polaris-Card {
      max-width: 45%;
    }

    .Polaris-Card + .Polaris-Card {
      margin-top: 0;
      margin-left: 5rem;
    }
  }
}
