.sync-product-container {
  display: inline-grid;
  grid-template-columns: 60px auto 120px 100px 100px 100px;
  width: 100%;
  align-items: center;

  .new-count {
    color: green;
  }

  .obsolete-count {
    color: red;
  }

  .thumbnail-wrapper {
    &.crowdship {
      border: 1px solid #070739;

      .crowdship-logo {
        position: absolute;
        top: -57px;
        left: 44px;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .product-title {
    padding-left: 15px;
    display: flex;
    flex-direction: column;

    .crowdship-logo {
      padding-right: 0.3em;
    }

    .link-to-platform {
      width: fit-content;
      opacity: 0;
      transition: opacity 0.1s;

      span {
        transition: color 0.1s;
      }

      span:hover {
        color: #3c454e;
      }
    }
  }

  &.expanded {
    .product-title .link-to-platform {
      opacity: 1;
    }
  }

  &:hover {
    .product-title .link-to-platform {
      opacity: 1;
    }
  }
}
