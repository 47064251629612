.no-orders {
  padding: 1em 2em;
}
.spinner-wrapper {
  margin: 0 auto;
  width: min-content;
}
.order-link {
  cursor: pointer;
}
