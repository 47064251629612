.product-grid {
  padding: 0.7em 0;

  .Polaris-Card__Header {
    padding-left: 1rem;
  }

  .filter-container {
    padding: 16px;
    display: flex;
    flex-direction: column;

    &__sku-limit {
      margin: 1rem 1rem 0;
    }

    .filters {
      max-width: 100%;
      flex: 1;
    }
  }

  &--selected {
    margin-top: 1rem;
  }

  &__view {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-top: 1rem;

    @media screen and (max-width: 65.9375em) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 3rem;
    }

    @media screen and (max-width: 55.9375em) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 25.9375em) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__spinner {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    padding: 3rem;
  }
}
