.shipping-mapping {
  margin-bottom: 30px;

  .narrow-text-field {
    padding-top: 10px;
  }

  .free-shipping-options {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    align-items: center;
    .dash {
      width: 10px;
      height: 2px;
      margin-top: 24px;
      margin-right: 10px;
      border: 1px solid gray;
    }
  }

  .Polaris-TextField {
    margin-right: 10px;
  }

  input[aria-labelledby*='Time'] {
    max-width: 50px;
  }
}
