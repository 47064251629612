.Polaris-DataTable__Cell {
  &--numeric {
    text-align: left;
  }

  .variant-supplier-price,
  .variant-retailer-price {
    width: 90px;
  }

  .edit-icon {
    opacity: 0;
    margin-left: 0.5em;
  }

  .custom-price {
    opacity: 1;
  }

  &:hover .edit-icon {
    opacity: 0.3;

    &:hover {
      opacity: 0.6;
    }
  }

  &:hover .custom-price {
    opacity: 1;

    &:hover {
      opacity: 1;
    }
  }

  .flex-block {
    display: flex;
    min-width: 90px;
  }
}
