.product-view {
  margin-bottom: 1rem;
  position: relative;

  .Polaris-Choice {
    position: absolute;
    top: 17px;
    right: 1%;
  }

  .variants-tab {
    display: flex;
    align-items: center;

    &__count {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;
      border: 1px solid var(--p-text-subdued);
      width: 1.1rem;
      height: 1.1rem;
      font-size: 0.7rem;
      text-align: center;
      border-radius: 50%;
    }
  }

  .product-images {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;

    &__layout {
      position: relative;
      cursor: pointer;

      .Polaris-Choice {
        position: absolute;
        z-index: 1;
        top: 0.5rem;
        left: 0.5rem;
      }

      .Polaris-Thumbnail--sizeLarge {
        width: 14rem;
      }
    }
  }

  .Polaris-Tabs__Panel {
    padding: 0 1rem;

    .Polaris-Card__Section {
      padding: 1rem 0;
    }
  }

  &__actions {
    display: flex;

    .app-button:first-of-type {
      margin-right: auto;
    }

    .app-button:last-of-type {
      margin-left: 1rem;
    }
  }
}

.product-section {
  display: flex;

  .Polaris-Thumbnail--sizeLarge {
    height: 21rem;
    width: 15rem;
    border-radius: 5%;
    margin-right: 2rem;
  }

  &__info {
    flex: 1;

    &__links {
      display: flex;
      margin: 0.5rem 0;

      .Polaris-TextStyle--variationStrong {
        margin: 0 1rem 0 0.2rem;
      }

      .Polaris-Icon {
        margin-left: 0.5rem;
        margin-top: 0.1rem;
      }
    }
    &__inputs {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
  }
}
