.basic_formik_input {
  padding: 0.5rem 1.2rem;
  background: none;
  border: 0.2rem solid transparent;
  position: relative;
  border-radius: 3px;
  z-index: 2;
  width: 100%;
  text-align: right;
  &:focus {
    outline: none !important;
    border-color: #5c6ac4;
  }
}
.basic_formik_input_backdrop {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  border: 0.1rem solid var(--p-border, #c4cdd5);

  border-radius: var(--p-border-radius-base, 3px);
  box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, 0.05);
  pointer-events: none;
}
.form_input_error_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30px;
  font-weight: 400;
  line-height: 2.4rem;
  text-transform: initial;
  letter-spacing: initial;
  position: relative;
  display: flex;
  align-items: center;
  color: #637381;
  cursor: text;
  .form_input_wrapper {
    height: 30px;
    display: flex;
  }
  .form_error_wrapper {
    z-index: 1000;
  }
}
