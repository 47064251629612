.order-item .Polaris-Card__Subsection {
  display: grid;
  grid-row-gap: 0.8rem;
  .line-item-row {
    display: grid;
    grid-gap: 0.8rem;
    grid-template-columns: 5em auto 7em 7em 6em 7em 7em;
    .title {
      font-weight: 600;
    }
    .image {
      margin-right: 1rem;
    }
    .total {
      text-align: right;
      font-weight: 600;
    }
  }
}
