.onboarding-modal {
  // width: 300px;
  img {
    width: 14em;
    padding: 1em 1em 0;
  }
  .Polaris-Card {
    box-shadow: 0 0;
    .Polaris-Button--primary,
    .Polaris-Button--primary:hover,
    .Polaris-Button--primary:active {
      background: #070739;
      border-color: #070739;
      box-shadow: 0 0;
    }
  }
}
