.banner {
  margin: 0 auto;
  padding: 1em 2.2rem;
  max-width: 80.25rem;
  width: 90vw;
}

.in-page-banner {
  padding-bottom: 1em;
}
