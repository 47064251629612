%show-icon-when-hovered {
  .edit-icon {
    opacity: 0;
    margin-left: 0.5em;
  }

  .custom-price {
    opacity: 1;
  }

  &:hover .edit-icon {
    opacity: 0.3;

    &:hover {
      opacity: 0.6;
    }
  }

  &:hover .custom-price {
    opacity: 1;

    &:hover {
      opacity: 1;
    }
  }

  .flex-block {
    display: flex;
  }
}

.Polaris-DataTable__Cell {
  @extend %show-icon-when-hovered;

  .variant-retailer-price {
    max-width: 120px;
  }

  &--numeric {
    text-align: left;
  }
}

.Polaris-IndexTable__TableRow {
  @extend %show-icon-when-hovered;

  .variant-retailer-price {
    max-width: 80px;
    float: right;
  }
}

.supplier-sku {
  color: gray;
}

.variant-list {
  padding: 0.7em 0;

  .Polaris-Card__Header {
    padding-left: 1rem;
  }

  .filter-container {
    padding: 16px;
    display: flex;

    .filters {
      flex: 1;
    }
  }

  .processing-tag {
    display: flex;

    .description {
      padding-left: 0.5em;
    }
  }
}

.price-sync {
  cursor: pointer;

  .price-value {
    text-align: right;
  }

  > span {
    display: flex;

    .Polaris-Icon {
      margin: 0 0.3rem 0 0;
    }
  }
}

.cursor-default {
  .price-value {
    text-align: right;
  }

  > span {
    display: flex;

    .Polaris-Icon {
      margin: 0 0.3rem 0 0;
    }
  }
}
