.suppliers-view {
  padding: 0.7em 0;

  .suppliers-list {
    margin-top: 1rem;
  }

  .Polaris-Stack--vertical {
    padding: 4rem;
  }

  .Polaris-Card {
    margin-bottom: 1rem;
  }

  &__spinner {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    padding: 3rem;
  }
}
