.sync-product-wrapper {
  display: flex;
  flex-direction: row;

  div.product-image {
    margin-top: 0;
    margin-right: 10px;
  }

  .sync-product-description {
    display: flex;
    flex-direction: column;

    .cost-msrp {
      display: flex;
      flex-direction: row;

      .msrp-value {
        margin-left: 30px;
      }
    }

    .Polaris-TextStyle--variationStrong:nth-child(5) {
      display: flex;
      align-items: center;
      text-align: center;
    }

    .fields-values {
      font-weight: normal;

      &.qty {
        margin-left: 0.5rem;
      }
    }
  }
}

.empty-sync-with-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 10px;
  }
}
