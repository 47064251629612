.import-list-wrapper {
  .Polaris-Page {
    max-width: 80.25rem;

    .Polaris-Page-Header {
      padding-bottom: 0.5rem;
    }

    .import-list__no-results {
      .Polaris-Stack {
        padding: 8rem;
      }
    }
  }

  .Polaris-SkeletonPage__Page {
    max-width: 80.25rem;
  }
}
