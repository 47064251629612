@keyframes Polaris-Spinner--loading {
  100% {
    transform: rotate(1turn);
  }
}

.spinner-replica {
  svg {
    animation: Polaris-Spinner--loading 0.5s linear infinite;
    height: 4.4rem;
    width: 4.4rem;
    fill: #47c1bf;
  }
}
