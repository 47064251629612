.filter-wrapper {
  padding: 1.6rem;
}
.wrapper {
  padding: 0.4rem 1.6rem;
}
.icon-wrapper {
  padding: 0 0 0 1rem;
}
.amount {
  display: flex;
}
