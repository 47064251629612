.auth-page {
  .small-card {
    padding: 10em 12em;
    .sign-in-separator {
      margin: 2em 0;

      display: flex;
      align-items: center;
      text-align: center;
      &:before {
        margin-right: 0.5em;
        flex: 1;
        border-bottom: 1px solid #eaeaea;
        content: '';
      }
      &:after {
        margin-left: 0.5em;
        flex: 1;
        border-bottom: 1px solid #eaeaea;
        content: '';
      }
    }
  }
}
