.conflict-list {
  margin-bottom: 2em;
  .conflict {
    padding-top: 1em;
    .product-option-list {
      padding: 1em;

      // Imitating Resource List
      // - full width
      // - centered elements
      .Polaris-Choice {
        display: flex;
        align-items: center;
        justify-content: normal;
        .Polaris-Choice__Label {
          flex: auto;
        }
      }
    }
    .conflicting-product {
      margin: 1rem 1.5rem 0 4rem;
    }
  }
  .no-conflicts {
    padding: 1em;
  }
}
