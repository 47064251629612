.tags-field {
  margin-top: 0.5rem;

  &--headings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }

  .Polaris-TextField__VerticalContent {
    display: flex;
    flex-wrap: wrap;
    overflow: unset;
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    padding: 0.5rem;

    .Polaris-Stack {
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
    }

    .Polaris-TextField__Input {
      height: 1.6rem;
      max-width: 25%;
      min-width: 5rem;
      max-width: 25%;
      min-height: 1.25rem;
      border-radius: 0.25rem;
      background-color: var(--p-surface-neutral);
      padding: 0 0.5rem;
    }
  }
}
