.availability-modal {
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 1rem;
  }

  .Polaris-Choice {
    min-height: 2.25rem;
  }

  .Polaris-OptionList {
    padding: 0;

    &-Option__Label {
      padding: 0;
    }

    &-Option__Checkbox {
      margin-left: 0;
    }
  }
}
