.Polaris-IndexTable__TableRow {
  .Polaris-IndexTable__TableCell {
    white-space: unset;
  }

  .product-image {
    margin: 1em 0;
  }

  .product-title {
    display: flex;
    flex-direction: column;

    .link-to-platform {
      width: fit-content;
      opacity: 0;
      transition: opacity 0.1s;
      text-decoration: none;

      span {
        transition: color 0.1s;
      }

      span:hover {
        color: #3c454e;
      }

      &.visible {
        opacity: 1;
      }
    }
  }

  .product-supplier {
    display: flex;
    white-space: nowrap;

    span {
      margin-left: 8px;
    }
  }

  &.Polaris-IndexTable__TableRow--hovered {
    .product-title .link-to-platform {
      opacity: 1;
    }
  }

  .expand-variants-btn {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.undelined-text {
      span.Polaris-TextStyle--variationPositive {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .connected-product-count .Polaris-Button--plain {
      color: var(--p-text-success);
    }
  }
}
