.connect {
  display: flex;
  justify-content: space-between;

  .account-img {
    padding-right: 1.5em;
  }

  .account-name {
    align-self: center;
  }

  .account-base {
    display: flex;
  }
}

.manage-payment {
  margin: 0 auto;
}

iframe {
  border: none;
}

.profile-image {
  width: 100%;

  .Polaris-Banner {
    margin-top: 2rem;
  }

  .Polaris-Layout__AnnotatedSection {
    padding: var(--p-space-4) 0;
    border-bottom: var(--p-border-divider);

    .Polaris-Layout__AnnotationContent {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .Polaris-Card {
        height: fit-content;

        .Polaris-Card__Section {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .Polaris-Spinner {
      margin: auto 0 auto 1.5rem;
    }
  }

  &__description {
    margin-right: 1rem;

    p {
      margin-top: 0.5rem;
    }
  }

  &__logo {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
    margin-left: 1.5rem;

    .Polaris-Thumbnail {
      width: 5.6rem;
      margin-bottom: 0.5rem;
    }
  }
}
