.order-details-summary {
  display: flex;
  gap: 25px;
  .totals-desc-list {
    display: grid;
    grid-row-gap: 0.8rem;

    .totals-desc-row {
      display: grid;
      grid-gap: 0.8rem;
      grid-template-columns: 10em auto 6em 6em;

      .desc {
        color: #637381;
      }

      &.bold {
        .title {
          font-weight: 600;
        }
      }
    }

    &.bold {
      font-weight: 600;
    }
  }

  .Polaris-Card {
    .Polaris-Card__Header {
      div {
        .needs-attention {
          display: inline-block;
          .mailLink {
            color: rgba(44, 110, 203, 1);
          }
        }
      }
    }
  }

  .line-items-list {
    .Polaris-Card__Subsection {
      display: grid;
      grid-row-gap: 0.8rem;

      .line-item-row {
        display: grid;
        grid-gap: 0.8rem;
        grid-template-columns: 5em auto 10em 7em 9em 7em 6em 6em;

        .title {
          font-weight: 600;
        }

        .variant,
        .title {
          .sku-with-overflow {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        .title span,
        .variant span {
          display: inline-block;
          /* WebKit-based browsers will ignore this. */
          max-width: -moz-available;
          /* Mozilla-based browsers will ignore this. */
          max-width: -webkit-fill-available;
          max-width: stretch;
        }

        .italic {
          font-style: italic;
        }

        .image {
          margin-right: 1rem;
        }
      }
    }

    &.no-tracking .Polaris-Card__Subsection .line-item-row {
      grid-template-columns: 5em auto 10em 7em 7em 6em 6em;
    }
  }

  .reason {
    padding-bottom: 1em;
  }

  .tracking-number-or-company {
    width: 105px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .main-info {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
  }

  .nested-banner {
    margin-bottom: 2em;
  }

  .order-details-summary-layout {
    flex-grow: 1;
  }

  .shipping {
    width: 315px;
  }
}
