.address-form {
  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;

    margin-bottom: 1rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    .app-button:first-of-type {
      margin-right: auto;
    }

    .app-button:last-of-type {
      margin-left: 1rem;
    }
  }
}
