.purchase-order-details {
  .totals-desc-list {
    display: grid;
    grid-row-gap: 0.8rem;

    .totals-desc-row {
      display: grid;
      grid-gap: 0.8rem;
      grid-template-columns: 10em auto 7em 7em;

      .desc,
      .shipping {
        color: #637381;
      }

      &.bold {
        .title {
          font-weight: 600;
        }
      }
    }

    .not-bold {
      font-weight: normal;
    }

    &.bold {
      font-weight: 600;
    }
  }

  .line-items-list {
    .Polaris-Card__Subsection {
      display: grid;
      grid-row-gap: 0.8rem;

      .line-item-row {
        display: grid;
        grid-gap: 0.8rem;
        grid-template-columns: 5em auto 10em 9em 7em 7em 7em;

        .title {
          font-weight: 600;
        }

        .variant,
        .title {
          .sku-with-overflow {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .title span,
        .variant span {
          display: inline-block;
          /* WebKit-based browsers will ignore this. */
          max-width: -moz-available;
          /* Mozilla-based browsers will ignore this. */
          max-width: -webkit-fill-available;
          max-width: stretch;
        }

        .italic {
          font-style: italic;
        }

        .image {
          margin-right: 1rem;
        }
      }
    }

    &.no-tracking .Polaris-Card__Subsection .line-item-row {
      grid-template-columns: 5em auto 10em 7em 7em 7em;
    }
  }

  .reason {
    padding-bottom: 1em;
  }

  .tracking-number-or-company {
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Polaris-Banner {
    margin-bottom: 1rem;
  }

  .mailLink {
    color: rgba(44, 110, 203, 1);
  }
}

.main-layout {
  display: flex;
  gap: 25px;
  .layout-purchase-order-details {
    flex-grow: 1;
  }

  .main-info {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .shipping {
      width: 315px;
      .invoices {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        width: fit-content;
        .arrow {
          width: 16px;
          height: 16px;
        }
        .reverted-arrow {
          transform: rotate(180deg);
        }
      }
      .invoices-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1px;
        .invoice-price {
          padding-left: 0.2rem;
        }
        .refunded-price {
          text-decoration: line-through;
          color: #93939a;
        }
      }
      .invoice-wrapper {
        display: flex;
        .provider {
          padding-right: 0.3rem;
        }
        .refunded-item {
          display: flex;
          gap: 5px;
        }
      }
      .invoice-wrapper span {
        padding-left: 0;
      }
      .invoice-wrapper span .icon-wrapper {
        padding-left: 0.3rem;
      }
    }
  }
}
