.product-level-section {
  display: flex;
  flex-direction: column;

  .carousel-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .product-image {
      padding-right: 4em;
      width: 55%;
      .carousel .control-next.control-arrow:before {
        border-left: 8px solid #808080;
      }
      .carousel .control-prev.control-arrow:before {
        border-right: 8px solid #808080;
      }
    }

    .near-carousel-section {
      width: 45%;
      div {
        font-size: large;
      }
      .product-title {
        display: flex;
        flex-direction: column;
        span {
          font-size: medium;
        }
      }
      .fulfilled-by {
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .b2b-msrp-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        .b2b,
        .msrp {
          display: flex;
          flex-direction: column;
          padding-right: 50px;
        }

        .msrp {
          span {
            color: #019b60;
          }
        }
      }
      .product-type {
        margin-bottom: 10px;
        display: flex;
        span {
          font-size: medium;
        }
        .tooltip-wrapper {
          display: flex;
        }
      }
      .hover {
        cursor: pointer;

        span.Polaris-Icon {
          display: inline-block;
          margin-left: 3px;
        }
      }
      .add-button {
        margin-top: 10px;
        .Polaris-List--typeNumber {
          font-size: small;
        }
        .Polaris-List--typeNumber {
          padding-left: 0;
          margin-left: 1.1em;
        }
      }
    }
  }

  .Polaris-Badge {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}

.variant-level-section {
  .text-field {
    .Polaris-Connected {
      max-width: 6em;
    }
  }
}

.variant-level-section {
  .basic_formik_input {
    padding: 0.5rem 0rem;
    width: 80px;
  }
}
