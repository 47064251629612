.request-product-modal {
  .supplier-block {
    display: flex;
    align-items: center;
    background-color: rgba(248, 248, 250, 1);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;

    .supplier-block__info {
      text-decoration: underline;
      margin: 0 1rem;
    }

    .supplier-block__icons {
      margin-left: auto;
    }
  }

  .Polaris-Banner {
    margin-bottom: 16px;
  }

  .request-form {
    display: flex;
    flex-direction: column;

    .Polaris-DisplayText {
      margin-bottom: 8px;
    }

    .Polaris-Labelled__LabelWrapper {
      margin-top: 8px;
    }

    .Polaris-ButtonGroup {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
