.settings-layout {
  .Polaris-Page {
    max-width: 80.25rem;
  }

  .subtitle {
    padding-bottom: 1rem;
  }

  .note {
    padding-top: 1rem;
  }

  .customs-declaration-section {
    display: grid;
    grid-auto-flow: row;
    row-gap: 1rem;
  }
}

.narrow-text-field {
  .Polaris-TextField {
    max-width: 30%;
  }

  &.separated {
    padding-top: 1em;
  }
}

.email-select {
  min-width: 120px;
}

.width-control {
  max-width: 20em;
}

.update-fields {
  color: #202223;
  padding-top: 1em;

  .Polaris-Card {
    box-shadow: none;
    outline: none;
  }
}

.company-section {
  width: 100%;

  .Polaris-Layout__AnnotatedSection {
    margin-bottom: var(--p-space-4);
  }

  &__profile {
    width: 100%;

    .Polaris-Banner {
      margin-top: 2rem;
    }

    .Polaris-Layout__AnnotatedSection {
      padding: var(--p-space-4) 0;
      border-bottom: var(--p-border-divider);
      margin-bottom: 0;

      .Polaris-Layout__AnnotationContent {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .Polaris-Card {
          height: fit-content;

          .Polaris-Card__Section {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      .Polaris-Spinner {
        margin: auto 0 auto 1.5rem;
      }
    }

    &__description {
      margin-right: 1rem;

      p {
        margin-top: 0.5rem;
      }
    }

    &__logo {
      display: flex;
      flex-direction: column;
      width: fit-content;
      align-items: center;
      margin-left: 1.5rem;

      .Polaris-Thumbnail {
        width: 5.6rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
