.supplier-order-details {
  .totals-desc-list {
    display: grid;
    grid-row-gap: 0.8rem;

    .totals-desc-row {
      display: grid;
      grid-gap: 0.8rem;
      grid-template-columns: 10em auto 9em;

      .desc {
        color: #637381;
      }

      &.bold {
        .title {
          font-weight: 600;
        }
      }
    }
  }

  .fulfillment {
    span {
      display: flex;
    }
  }

  .retail-line-items-list {
    .Polaris-Card__Subsection {
      display: grid;
      grid-row-gap: 0.8rem;

      .line-item-row {
        display: grid;
        grid-gap: 0.8rem;
        grid-template-columns: 5em auto 9em 8em 9em 9em;

        .title {
          font-weight: 600;
        }

        .image {
          margin-right: 1rem;
        }
      }
    }

    &.no-tracking .Polaris-Card__Subsection .line-item-row {
      grid-template-columns: 5em auto 8em 9em 9em;
    }
  }

  .tracking-number-or-company {
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .main-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    .shipping {
      min-width: 300px;
    }
  }
}

.order-labels {
  padding: 1rem;

  h2 {
    padding: 1em 0;
  }

  .unfulfilled-product-groups {
    .label-candidate {
      padding: 0.5em 0;

      .quantity {
        width: 8em;
      }

      .label-candidate-footer {
        display: grid;
        grid-row-gap: 0.8rem;

        .label-candidate-footer--row {
          display: grid;
          grid-template-columns: 15em auto 13em;
          justify-items: flex-start;
        }
      }
    }
  }

  .labeled-group {
    padding: 0.5em 0;
  }
}
