.cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .plan-card {
    width: 250px;
    margin-right: 1em;
    margin-bottom: 1em;
    .Polaris-Card__Section {
      min-height: 105px;
      &:last-child {
        height: 121px;
      }
    }
    .description {
      padding-bottom: 1em;
    }
    .change-plan-section {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
    }
    .unavailability-reason {
      padding-bottom: 1em;
    }
    .subscribed {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      //  height: 36px;
      height: 100%;
      padding-bottom: 0.5em;
      span {
        margin: 0;
      }
    }
    .app-button {
      margin: 0 auto;
    }
  }
}
