.order-details-layout {
  .Polaris-Page {
    max-width: none;
  }
}

.empty-order-page {
  margin: 215px auto 0 auto;
}

.Polaris-Page-Header {
  padding-bottom: 0;
}

.order-date {
  width: fit-content;
  padding: 5px 0 10px;
}
