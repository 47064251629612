.dashboard_card_summary_wrapper {
  display: flex;
  justify-content: space-between;
}
.dashboard_card_title,
.dashboard_card_value {
  text-transform: capitalize;
}

.Polaris-Stack--distributionLeading {
  justify-content: center;
}
