.Polaris-Button--iconOnly {
  padding: 0;
  border: none;
  box-shadow: none;
}

.app-switch .Polaris-Icon {
  width: 32px;
  height: 32px;
}
