.original_address-wrapper {
  display: flex;
  gap: 4px;
  .edited_address {
    font-style: italic;
    font-size: 12px;
  }
  .link {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    color: blue;
  }
}
